import { PoweredBy } from "react-instantsearch-dom";

const Footer = () => (
  <footer>
    <p>
      Data was gathered by the{" "}
      <a href={"https://digitalcommons.usu.edu/dg_acknowledge/"}>
        Differential Geometry, Computer Algebra, Mathematical Physics
      </a>{" "}
      group at Utah State University from:
    </p>
    <cite>
      <p>
        Hawking, S., & Ellis, G. (1973). The Large Scale Structure of Space-Time
        (Cambridge Monographs on Mathematical Physics). Cambridge: Cambridge
        University Press.
        <a href={"doi:10.1017/CBO9780511524646"}>
          {" "}
          doi:10.1017/CBO9780511524646
        </a>
      </p>
      <p>
        Stephani, H., Kramer, D., MacCallum, M., Hoenselaers, C., & Herlt, E.
        (2003). Exact Solutions of Einstein's Field Equations (2nd ed.,
        Cambridge Monographs on Mathematical Physics). Cambridge: Cambridge
        University Press.{" "}
        <a href={"doi:10.1017/CBO9780511535185"}>
          {" "}
          doi:10.1017/CBO9780511535185
        </a>
      </p>
    </cite>
    <PoweredBy />
  </footer>
);

export default Footer;
