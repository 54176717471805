import { Panel, RefinementList } from "react-instantsearch-dom";

const Facets = () => (
  <div className={"left-panel"}>
    <div id={"primary-description-facet"}>
      <Panel header={"Primary Description"}>
        <RefinementList
          attribute={"PrimaryDescription"}
          searchable={false}
          operator={"and"}
        />
      </Panel>
    </div>
    <div id={"secondary-description-facet"}>
      <Panel header={"Secondary Description"}>
        <RefinementList
          attribute={"SecondaryDescription"}
          searchable={false}
          operator={"and"}
        />
      </Panel>
    </div>
  </div>
);

export default Facets;
