const Header = () => (
  <header>
    <h1>Exact Solutions of Einstein's Field Equations</h1>
    <a
      className={"einstein-field-equations"}
      href={
        "https://en.wikipedia.org/wiki/Exact_solutions_in_general_relativity"
      }
    >
      <img alt={"Exact Solutions In General Relativity"} src={"/img/efe.svg"} />
    </a>
  </header>
);

export default Header;
