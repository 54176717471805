import { Highlight } from "react-instantsearch-dom";

const SEARCHABLE_ATTRIBUTES = [
  "Reference",
  "EquationNumber",
  "Authors",
  "PrimaryDescription",
  "SecondaryDescription",
  "TertiaryDescription",
  "Bugs",
  "Comments",
];

const NON_DISPLAY_KEYS = ["objectID", "_highlightResult", "__position"];

function Hit({ hit }) {
  const highlight_attributes = Object.keys(hit._highlightResult).sort(
    (a, b) =>
      SEARCHABLE_ATTRIBUTES.indexOf(a) - SEARCHABLE_ATTRIBUTES.indexOf(b)
  );
  const remainder_attributes = Object.keys(hit).filter(
    (attribute) =>
      ![...NON_DISPLAY_KEYS, ...SEARCHABLE_ATTRIBUTES].includes(attribute)
  );
  return (
    <div className={"hit"} key={hit.id}>
      <dl className={"solution-attributes"}>
        {highlight_attributes.map((key) => (
          <>
            <dt>{key.replace(/([a-z])([A-Z])/g, "$1 $2")}:</dt>
            <dd>
              <Highlight attribute={key} hit={hit} />
            </dd>
          </>
        ))}
        {remainder_attributes.map(
          (key) =>
            hit[key] && (
              <>
                <dt>{key.replace(/([a-z])([A-Z])/g, "$1 $2")}:</dt>
                <dd>{hit[key]}</dd>
              </>
            )
        )}
      </dl>
    </div>
  );
}

export default Hit;
