import { Hits, Pagination, SearchBox, Stats } from "react-instantsearch-dom";
import Hit from "./Hit";
import Footer from "./Footer";

const Results = () => (
  <div className={"right-panel"}>
    <div id={"searchbox"}>
      <SearchBox
        translations={{
          placeholder: "Search for exact solutions",
        }}
      />
    </div>
    <Stats />
    <div id={"hits"}>
      <Hits hitComponent={Hit} />
      <Pagination showPrevious={false} showNext={false} />
      <Footer />
    </div>
  </div>
);

export default Results;
