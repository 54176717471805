import "./App.css";
import { InstantSearch, ScrollTo } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import Header from "./components/Header";
import Facets from "./components/Facets";
import Results from "./components/Results";

const {
  REACT_APP_ALGOLIA_APPLICATION_ID,
  REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
} = process.env;

if (
  !REACT_APP_ALGOLIA_APPLICATION_ID ||
  !REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY
) {
  throw new Error(
    "Please configure the env variables [REACT_APP_ALGOLIA_APPLICATION_ID] and [REACT_APP_ALGOLIA_PUBLIC_API_KEY] in your .env file"
  );
}

const searchClient = algoliasearch(
  REACT_APP_ALGOLIA_APPLICATION_ID,
  REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY
);

function App() {
  return (
    <InstantSearch indexName="exact_solutions" searchClient={searchClient}>
      <ScrollTo>
        <Header />
      </ScrollTo>
      <main className={"search-container"}>
        <Facets />
        <Results />
      </main>
    </InstantSearch>
  );
}

export default App;
